<template>
  <div class="create-content-main">
    <div style="height: 42px; width: 100%; position: relative">
      <div class="col col-auto px-0 div-preview-btn">
        <button
          type="button"
          class="btn btn-preview-fixed btn-primary mr-0"
          v-on:click.prevent="preview()"
        >
          プレビュー
        </button>
      </div>
    </div>
    <div
      class="create-slag-form background-content-admin pt-2 position-relative"
    >
      <div class="create-slag-form">
        <div class="row ml-0 pt-2">
          <h2
            class="col col-auto font-title font-weight-bold cpx-4 my-auto"
            style="margin-right: 10px; bottom: 5px"
          >
            マイページ作成
          </h2>
          <div class="col col-auto">
            <div class="mx-3">
              <label
                v-for="(item, index) in listSetting"
                :key="index"
                class="mr-3 position-relative py-2"
                :class="
                  typeSetting === item.value
                    ? 'btn-tab-sale-active'
                    : 'btn-tab-sale-inactive'
                "
                @click="typeSetting = item.value"
                style="font-weight: normal !important; font-size: 17px"
              >
                {{ item.text }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <form class="pt-0" id="create-content" autocomplete="off">
        <div v-if="typeSetting === 1">
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >ページタイトル<span class="required">(必須)</span></label
            >
            <div class="cp-4 col-12">
              <input
                class="form-input m-0"
                type="text"
                v-model="infoNew.title"
                placeholder="ページタイトル"
              />
            </div>
          </div>
        </div>
        <div
          v-if="infoNew.description.length"
          class="row row-input"
          :style="{ display: typeSetting === 1 ? '' : 'none' }"
        >
          <label class="col-sm-12 font-weight-bold cp-label">ページ本文</label>
          <div
            v-for="(value, index) in infoNew.description"
            :key="index"
            :id="'editorId' + index"
            class="w-100"
            style="padding: 0 40px; margin: 20px 0px"
          >
            <div class="mb-2">
              <div
                class="col-12 mx-auto justify-content-center p-0 setting-slag setting-slag-fixed-page"
              >
                <b-icon
                  icon="gear-fill"
                  class="my-auto"
                  font-scale="2.5"
                  style="border-color: #ced2d8; padding: 4px"
                  v-on:click="showSlagConditionDetail(value, index)"
                />
                <b-button
                  v-if="infoNew.description.length > 1"
                  variant="dark"
                  :disabled="isDisabled"
                  class="rounded-circle custome-btn-sub float-right mt-0 bt-sub"
                  style="
                    border-radius: 50% !important;
                    margin-left: 15px;
                    background: #333;
                  "
                  v-on:click="confirmDelContent(index)"
                  ><b-icon icon="dash" scale="2"
                /></b-button>
                <b-button
                  v-if="infoNew.description.length > 1"
                  variant="dark"
                  :disabled="isDisabled"
                  class="rounded-circle custome-btn-sub float-right mt-0 bt-sub"
                  style="
                    border-radius: 50% !important;
                    margin-left: 5px;
                    background: #333;
                  "
                  v-on:click="downContent(index)"
                  ><b-icon icon="arrow-down-circle" scale="2"
                /></b-button>
                <b-button
                  v-if="infoNew.description.length > 1"
                  variant="dark"
                  :disabled="isDisabled"
                  class="rounded-circle custome-btn-sub float-right mt-0 bt-sub"
                  style="border-radius: 50% !important; background: #333"
                  v-on:click="upContent(index)"
                  ><b-icon icon="arrow-up-circle" scale="2"
                /></b-button>
                <div
                  class="d-flex"
                  style="
                    width: fit-content;
                    float: right;
                    margin-right: 25px;
                    padding-top: 2px;
                  "
                >
                  <div
                    v-on:click="undo(index)"
                    style="cursor: pointer; margin-right: 10px; margin-top: 3px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      class="bi bi-arrow-90deg-left"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M1.146 4.854a.5.5 0 0 1 0-.708l4-4a.5.5 0 1 1 .708.708L2.707 4H12.5A2.5 2.5 0 0 1 15 6.5v8a.5.5 0 0 1-1 0v-8A1.5 1.5 0 0 0 12.5 5H2.707l3.147 3.146a.5.5 0 1 1-.708.708l-4-4z"
                      />
                    </svg>
                  </div>
                  <div
                    v-on:click="redo(index)"
                    style="cursor: pointer; margin-right: 15px; margin-top: 3px"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      class="bi bi-arrow-90deg-right"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M14.854 4.854a.5.5 0 0 0 0-.708l-4-4a.5.5 0 0 0-.708.708L13.293 4H3.5A2.5 2.5 0 0 0 1 6.5v8a.5.5 0 0 0 1 0v-8A1.5 1.5 0 0 1 3.5 5h9.793l-3.147 3.146a.5.5 0 0 0 .708.708l4-4z"
                      />
                    </svg>
                  </div>
                  <div v-on:click="copyPage(index)" style="cursor: pointer">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="33"
                      height="33"
                      fill="currentColor"
                      class="bi bi-files"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M13 0H6a2 2 0 0 0-2 2 2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h7a2 2 0 0 0 2-2 2 2 0 0 0 2-2V2a2 2 0 0 0-2-2zm0 13V4a2 2 0 0 0-2-2H5a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1zM3 4a1 1 0 0 1 1-1h7a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4z"
                      />
                    </svg>
                  </div>
                  <div
                    v-on:click="pastePage(index, $event)"
                    class="ml-2"
                    style="cursor: pointer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="33"
                      height="33"
                      fill="currentColor"
                      class="bi bi-clipboard"
                      viewBox="0 0 16 16"
                    >
                      <path
                        d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z"
                      />
                      <path
                        d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <input
                type="file"
                hidden
                ref="selectFile"
                id="idSelectFile"
                accept=".pdf"
                @change="changePDF($event, index)"
              />
              <div
                v-if="value.show"
                class="position-relative w-100"
                :id="'ed' + index"
              >
                <editor
                  v-if="!isEdit"
                  v-bind:id="`editor-${index}`"
                  @dataEditor="setValue($event, index)"
                  @onReady="onReady($event)"
                  @onChange="editorChange($event)"
                  v-bind:isEdit="createFp ? true : isEdit"
                  v-bind:dataEditor="
                    value.description ? JSON.stringify(value.description) : ''
                  "
                ></editor>
                <editor
                  v-if="isEdit && index == 0 && checkPreview != 3"
                  v-bind:id="`editor-${index}`"
                  @dataEditor="setValue($event, index)"
                  @onReady="onReady($event)"
                  @onChange="editorChange($event)"
                  v-bind:dataEditor="
                    value.description ? JSON.stringify(value.description) : ''
                  "
                  class="mb-4"
                  v-click-outside="changeStatusEdit"
                ></editor>
                <editor
                  v-if="
                    (isEdit && index > 0) ||
                    (isEdit && index == 0 && checkPreview == 3)
                  "
                  v-bind:id="`editor-${index}`"
                  @dataEditor="setValue($event, index)"
                  @onReady="onReady($event)"
                  @onChange="editorChange($event)"
                  v-bind:dataEditor="
                    value.description ? JSON.stringify(value.description) : ''
                  "
                  v-bind:isEdit="isEdit"
                  v-click-outside="changeStatusEdit"
                  class="mb-4"
                ></editor>
                <b-spinner
                  v-if="loadingUpload"
                  style="
                    width: 100%;
                    height: 3rem;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                  "
                  large
                ></b-spinner>
              </div>
            </div>
            <div
              class="row row-input justify-content-center aligns-items-center"
            >
              <b-icon
                icon="plus"
                :disabled="isDisabled"
                class="h1 rounded-circle add-new-description mt-3"
                @click="addNewDescription(index)"
                font-scale="3"
                style="color: #fff; background: #333"
              ></b-icon>
            </div>
          </div>
        </div>
        <div>
          <b-modal
            id="modal-detail-condition"
            modal-class="modalCustom"
            style="width: 720px"
            hide-header
          >
            <div class="d-block text-center"></div>
            <!-- Content Condition -->
            <div class="px-3 customBackGround">
              <div class="p-2">
                <div class="row mb-3 row-input pl-3">
                  <label class="col-sm-12 font-weight-bold pl-0"
                    >基本設定(以下のコンテンツを持っている人)</label
                  >
                  <div
                    class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                  >
                    <div class="custom-select-2 w-100 custome-multi-slag">
                      <multiselect
                        class="col-sm-12 col-lg-12 p-0"
                        v-model="content_condition_1"
                        placeholder="コンテンツを選択してください"
                        label="text"
                        track-by="value"
                        :options="listContentOptions"
                        :multiple="true"
                        :taggable="false"
                        :showNoResults="false"
                        :select-label="'入力して選択する'"
                        :deselect-label="'入力して削除する'"
                        :selected-label="'選択された'"
                        @input="changeMutiSelectContentCondition()"
                      ></multiselect>
                    </div>
                  </div>
                </div>
                <div class="row mb-3 row-input pl-3">
                  <label class="col-sm-12 font-weight-bold pl-0"
                    >and1(基本設定とand1両方のコンテンツを持つユーザーだけに表示)</label
                  >
                  <div
                    class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                  >
                    <div class="custom-select-2 w-100 custome-multi-slag">
                      <multiselect
                        class="col-sm-12 col-lg-12 p-0"
                        v-model="content_condition_2"
                        placeholder="コンテンツを選択してください"
                        label="text"
                        track-by="value"
                        :options="listContentOptions"
                        :multiple="true"
                        :taggable="false"
                        :showNoResults="false"
                        :select-label="'入力して選択する'"
                        :deselect-label="'入力して削除する'"
                        :selected-label="'選択された'"
                        @input="changeMutiSelectContentCondition()"
                        :disabled="disabledConditionContent2 === true"
                      ></multiselect>
                    </div>
                  </div>
                </div>
                <div class="row mb-3 row-input pl-3">
                  <label class="col-sm-12 font-weight-bold pl-0"
                    >and2(基本設定+and1+2の3点のコンテンツを持つユーザーだけに表示)</label
                  >
                  <div
                    class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                  >
                    <div class="custom-select-2 w-100 custome-multi-slag">
                      <multiselect
                        class="col-sm-12 col-lg-12 p-0"
                        v-model="content_condition_3"
                        placeholder="コンテンツを選択してください"
                        label="text"
                        track-by="value"
                        :options="listContentOptions"
                        :multiple="true"
                        :taggable="false"
                        :showNoResults="false"
                        :select-label="'入力して選択する'"
                        :deselect-label="'入力して削除する'"
                        :selected-label="'選択された'"
                        :disabled="disabledConditionContent3 === true"
                      ></multiselect>
                    </div>
                  </div>
                </div>
                <div class="row mb-3 row-input pl-3">
                  <label class="col-sm-12 font-weight-bold pl-0"
                    >非表示（以下のコンテンツを持つユーザーは非表示）</label
                  >
                  <div
                    class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                  >
                    <div class="custom-select-2 w-100 custome-multi-slag">
                      <multiselect
                        class="col-sm-12 col-lg-12 p-0"
                        v-model="content_condition_4"
                        placeholder="コンテンツを選択してください"
                        label="text"
                        track-by="value"
                        :options="listContentOptions"
                        :multiple="true"
                        :taggable="false"
                        :showNoResults="false"
                        :select-label="'入力して選択する'"
                        :deselect-label="'入力して削除する'"
                        :selected-label="'選択された'"
                      ></multiselect>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Slag condition  -->
            <div class="px-3 mt-4 customBackGround">
              <div class="p-2">
                <div class="row mb-3 row-input pl-3">
                  <label class="col-sm-12 font-weight-bold pl-0"
                    >基本設定(以下のスラグを持っている人)</label
                  >
                  <div
                    class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                  >
                    <div class="custom-select-2 w-100 custome-multi-slag">
                      <multiselect
                        v-model="slag_condition_1"
                        tag-placeholder="スラグを選択してください"
                        placeholder="スラグを選択してください"
                        label="slag_name"
                        track-by="slag_name"
                        :options="listSlagOptions"
                        :multiple="true"
                        :taggable="false"
                        :showNoResults="false"
                        :max-height="200"
                        select-label="入力して選択する"
                        selected-label="選択された"
                        deselect-label="入力して削除する"
                        @input="changeMutiSelectSlagCondition()"
                      >
                        <template v-slot:no-options>リストは空です</template>
                      </multiselect>
                    </div>
                  </div>
                </div>
                <div class="row mb-3 row-input pl-3">
                  <label class="col-sm-12 font-weight-bold pl-0"
                    >and1(基本設定とand1両方のスラグを持つユーザーだけに表示)</label
                  >
                  <div
                    class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                  >
                    <div class="custom-select-2 w-100 custome-multi-slag">
                      <multiselect
                        v-model="slag_condition_2"
                        tag-placeholder="スラグを選択してください"
                        placeholder="スラグを選択してください"
                        label="slag_name"
                        track-by="slag_name"
                        :options="listSlagOptions"
                        :multiple="true"
                        :taggable="false"
                        :showNoResults="false"
                        :max-height="200"
                        select-label="入力して選択する"
                        selected-label="選択された"
                        deselect-label="入力して削除する"
                        @input="changeMutiSelectSlagCondition()"
                        :disabled="disabledConditionSlag2 === true"
                      >
                        <template v-slot:no-options>リストは空です</template>
                      </multiselect>
                    </div>
                  </div>
                </div>
                <div class="row mb-3 row-input pl-3">
                  <label class="col-sm-12 font-weight-bold pl-0"
                    >and2(基本設定+and1+2の3点のスラグを持つユーザーだけに表示)</label
                  >
                  <div
                    class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                  >
                    <div class="custom-select-2 w-100 custome-multi-slag">
                      <multiselect
                        v-model="slag_condition_3"
                        tag-placeholder="スラグを選択してください"
                        placeholder="スラグを選択してください"
                        label="slag_name"
                        track-by="slag_name"
                        :options="listSlagOptions"
                        :multiple="true"
                        :taggable="false"
                        :showNoResults="false"
                        :max-height="200"
                        select-label="入力して選択する"
                        selected-label="選択された"
                        deselect-label="入力して削除する"
                        :disabled="disabledConditionSlag3 === true"
                      >
                        <template v-slot:no-options>リストは空です</template>
                      </multiselect>
                    </div>
                  </div>
                </div>
                <div class="row mb-3 row-input pl-3">
                  <label class="col-sm-12 font-weight-bold pl-0"
                    >非表示（以下のスラグを持つユーザーは非表示）</label
                  >
                  <div
                    class="d-flex justify-content-between align-items-center add-slag row col-sm-12"
                  >
                    <div class="custom-select-2 w-100 custome-multi-slag">
                      <multiselect
                        v-model="slag_condition_4"
                        tag-placeholder="スラグを選択してください"
                        placeholder="スラグを選択してください"
                        label="slag_name"
                        track-by="slag_name"
                        :options="listSlagOptions"
                        :multiple="true"
                        :taggable="false"
                        :showNoResults="false"
                        :max-height="200"
                        select-label="入力して選択する"
                        selected-label="選択された"
                        deselect-label="入力して削除する"
                      >
                        <template v-slot:no-options>リストは空です</template>
                      </multiselect>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <template #modal-footer="{}">
              <div class="">
                <b-button
                  v-on:click.prevent="updateCondition()"
                  variant="info"
                  class="mr-4"
                  >アップデート
                </b-button>
                <b-button
                  variant="secondary back-btn"
                  @click="closeModalCondition()"
                  class="mx-2"
                  >戻る</b-button
                >
              </div>
            </template>
          </b-modal>
        </div>
        <div v-if="typeSetting === 1">
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label">
              公開ステータス</label
            >
            <div class="w-100 cp-4">
              <b-form-select
                class="col-md-6 custome-text-select"
                id="select-cate"
                v-model="infoNew.is_public"
                :options="optionPublic"
              ></b-form-select>
            </div>
          </div>
        </div>
        <div v-if="typeSetting === 2">
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >表示非表示条件を設定する
            </label>
            <div class="cp-4 col-12">
              <div class="btn-toggle-active">
                <input
                  v-model="infoNew.condition_type"
                  class="m-0 d-none"
                  type="radio"
                  name="radio-type-header"
                  :value="1"
                  id="radio-type-header-0"
                />
                <label
                  for="radio-type-header-0"
                  :class="
                    infoNew.condition_type == 1
                      ? 'btn-share-freesize-active py-2 px-4'
                      : 'btn-share-freesize py-2 px-4'
                  "
                  >表示条件設定</label
                >
              </div>
              <div class="btn-toggle-active">
                <input
                  v-model="infoNew.condition_type"
                  class="m-0 d-none"
                  type="radio"
                  name="radio-type-header"
                  :value="2"
                  id="radio-type-header-1"
                />
                <label
                  for="radio-type-header-1"
                  :class="
                    infoNew.condition_type == 2
                      ? 'btn-share-freesize-active py-2 px-4'
                      : 'btn-share-freesize py-2 px-4'
                  "
                  >無条件で全員に表示</label
                >
              </div>
              <div class="btn-toggle-active">
                <input
                  v-model="infoNew.condition_type"
                  class="m-0 d-none"
                  type="radio"
                  name="radio-type-header"
                  :value="3"
                  id="radio-type-header-2"
                />
                <label
                  for="radio-type-header-2"
                  :class="
                    infoNew.condition_type == 3
                      ? 'btn-share-freesize-active py-2 px-4'
                      : 'btn-share-freesize py-2 px-4'
                  "
                  >無条件で全員非表示</label
                >
              </div>
            </div>
          </div>
          <div v-if="infoNew.condition_type == 1" class="row row-input">
            <div class="row w-100 cp-4">
              <div class="col-10">
                <div
                  class="d-flex align-items-center mb-3"
                  v-for="(value, index) in infoNew.condition_json"
                  :key="index"
                >
                  <input
                    class="form-input m-0 w-50"
                    type="text"
                    v-model="value.title"
                    placeholder="管理タイトル"
                  />
                  <b-button class="py-0 bt-setting">
                    <b-icon
                      icon="gear-fill"
                      class="my-auto"
                      font-scale="2.5"
                      style="color: #333"
                      v-on:click="settingCondition(value, index)"
                    />
                  </b-button>
                  <b-button
                    @click="removeListDisplay(index)"
                    style="color: white; width: 37px; height: 37px"
                    class="bg-333333"
                  >
                    <font-awesome-icon
                      class="sidebar-icon"
                      :icon="['fas', 'minus']"
                    />
                  </b-button>
                </div>
              </div>
            </div>
            <div class="w-75 text-center">
              <b-icon
                icon="plus"
                class="h1 rounded-circle add-new-description"
                @click="addTitle()"
                font-scale="3"
                style="color: #fff; background: #333"
              ></b-icon>
            </div>
          </div>
        </div>
        <div v-if="typeSetting === 3">
          <div class="row row-input">
            <label class="col-sm-12 font-weight-bold cp-label"
              >アクセス可能期間を設定する<span class="required"
                >(必須)</span
              ></label
            >
            <div
              class="fd-admin-listproperty-card-filter filter-date d-flex cp-4"
            >
              <date-picker
                name="date"
                v-model="infoNew.start_access_date"
                :config="optionsDate"
                autocomplete="off"
                class="custome-text-select m-0"
              ></date-picker>
              <span class="my-auto" style="font-size: 23px">~</span>
              <date-picker
                name="date"
                v-model="infoNew.end_access_date"
                :config="optionsDate"
                autocomplete="off"
                class="custome-text-select m-0"
              ></date-picker>
            </div>
          </div>
        </div>
        <div :style="{ display: typeSetting === 4 ? '' : 'none' }">
          <div class="setting-type-5">
            <div class="row mb-3 row-input align-items-top">
              <div class="col-sm-3 text-lg-left px-0">
                <label class="cp-label" for="post_banner-image-button">
                  ヘッダー画像
                </label>
              </div>
              <div class="custome-input-one-column">
                <label for="post_banner_header" class="btn btn-up-file mr-2"
                  >写真選択</label
                >
                <label
                  v-if="option1Column.urlHeaderImg"
                  class="btn btn-delete-file"
                  @click="deleteImg(1)"
                  >写真削除</label
                >
                <br />
                <span class="help-block" style="display: block">
                  <img
                    v-if="option1Column.urlHeaderImg"
                    class="preview-img-fixed preview-img-header"
                    id="post_banner-image-preview"
                    :src="option1Column.urlHeaderImg"
                  />
                </span>
                <input
                  type="file"
                  ref="headerFile"
                  name="post_banner_header"
                  id="post_banner_header"
                  class="d-none"
                  accept="image/jpg, image/png, image/jpeg"
                  @change="onChangeHeaderImage($event)"
                />
              </div>
            </div>
            <div class="row mb-3 row-input cp-4">
              <h5 class="pl-0" style="font-size: 1.57em">背景設定</h5>
            </div>
            <div class="row mb-3 row-input">
              <div class="col-sm-3 text-lg-left px-0 my-auto">
                <label class="cp-label" for="post_banner-image-button">
                  背景色
                </label>
              </div>
              <div class="custome-input-one-column">
                <v-input-colorpicker
                  v-model="infoNew.colorBackground"
                  class="input-color color-field position-absolute"
                  style="z-index: -1"
                />
                <input
                  class="input color-field position-relative"
                  style="width: 45%; margin-left: 0px"
                  type="text"
                  v-model="infoNew.colorBackground"
                  @click="changeColor('input-color')"
                  v-bind:style="{ background: this.infoNew.colorBackground }"
                />
                <span>色を選択</span>
              </div>
            </div>
            <div class="row mb-3 row-input align-items-center">
              <div class="col-sm-3 text-lg-left px-0">
                <label class="cp-label" for="post_banner-image-button">
                  背景画像
                </label>
              </div>
              <div class="custome-input-one-column">
                <label for="post_banner_background" class="btn btn-up-file mr-2"
                  >写真選択</label
                >
                <label
                  v-if="option1Column.urlBackground"
                  class="btn btn-delete-file"
                  @click="deleteImg(2)"
                  >写真削除</label
                >
                <br />
                <span class="help-block" style="display: block">
                  <img
                    v-if="option1Column.urlBackground"
                    class="preview-img-fixed preview-img-background"
                    id="post_banner-image-preview"
                    :src="option1Column.urlBackground"
                  />
                </span>
                <input
                  type="file"
                  ref="backgroundFile"
                  name="post_banner_background"
                  id="post_banner_background"
                  class="d-none"
                  accept="image/jpg, image/png, image/jpeg"
                  @change="onChangeBackgroundImage($event)"
                />
              </div>
            </div>
            <div class="row mb-3 row-input align-items-center">
              <div class="col-sm-3 text-lg-left px-0 my-auto">
                <label class="cp-label"> 背景画像繰り返し </label>
              </div>
              <div class="custome-input-one-column" style="margin-top: -10px">
                <label
                  class="custom-ListLabel"
                  style="cursor: pointer"
                  :for="`action-${index}`"
                  v-bind:class="{
                    selected: index === infoNew.repeatBackground,
                  }"
                  v-for="(item, index) in listRepeat"
                  :key="index"
                  @click="setBackgroundRepeat(item.value)"
                >
                  {{ item.text }}
                </label>
              </div>
            </div>
            <div class="row mb-3 row-input align-items-center">
              <div class="col-sm-3 text-lg-left px-0 my-auto">
                <label class="cp-label"> 背景画像固定 </label>
              </div>
              <div class="custome-input-one-column">
                <label
                  class="custom-ListLabel"
                  style="cursor: pointer"
                  :for="`action-${index}`"
                  v-bind:class="{
                    selected: index === infoNew.fixedBackground,
                  }"
                  v-for="(item, index) in listFixed"
                  :key="index"
                  @click="setBackgroundFixed(item.value)"
                >
                  {{ item.text }}
                </label>
              </div>
            </div>
            <div class="row mb-3 row-input align-items-center">
              <div class="col-sm-3 text-lg-left px-0 my-auto">
                <label class="cp-label"> 背景画像の配置 </label>
              </div>
              <div class="custome-input-one-column">
                <label
                  class="custom-ListLabel"
                  style="cursor: pointer"
                  :for="`action-${index}`"
                  v-bind:class="{
                    selected: index === infoNew.placeBackground,
                  }"
                  v-for="(item, index) in listPlace"
                  :key="index"
                  @click="setBackgroundPlace(item.value)"
                >
                  {{ item.text }}
                </label>
              </div>
            </div>
          </div>
          <div class="setting-type-5">
            <div class="row mb-3 row-input cp-4">
              <h5 class="pl-0" style="font-size: 1.57em">記事背景設定</h5>
            </div>
            <div class="row mb-3 row-input">
              <div class="col-sm-3 text-lg-left px-0 my-auto">
                <label class="cp-label" for="post_banner-image-button">
                  背景色
                </label>
              </div>
              <div class="custome-input-one-column">
                <v-input-colorpicker
                  v-model="infoNew.colorArticle"
                  class="input-color1 color-field position-absolute"
                  style="z-index: -1"
                />
                <input
                  class="input color-field position-relative"
                  style="width: 45%; margin-left: 0px"
                  type="text"
                  v-model="infoNew.colorArticle"
                  @click="changeColor('input-color1')"
                  v-bind:style="{ background: this.infoNew.colorArticle }"
                />
                <span>色を選択</span>
              </div>
            </div>
            <div class="row mb-3 row-input align-items-center">
              <div class="col-sm-3 text-lg-left px-0">
                <label class="cp-label" for="post_banner-image-button">
                  背景画像
                </label>
              </div>
              <div class="custome-input-one-column">
                <label for="post_banner_article" class="btn btn-up-file mr-2"
                  >写真選択</label
                >
                <label
                  v-if="option1Column.urlArticeBackground"
                  class="btn btn-delete-file"
                  @click="deleteImg(3)"
                  >写真削除</label
                >
                <br />
                <span class="help-block" style="display: block">
                  <img
                    v-if="option1Column.urlArticeBackground"
                    class="preview-img-fixed preview-img-artice"
                    id="post_banner-image-preview"
                    :src="option1Column.urlArticeBackground"
                  />
                </span>
                <input
                  type="file"
                  ref="articleFile"
                  name="post_banner_article"
                  id="post_banner_article"
                  class="d-none"
                  accept="image/jpg, image/png, image/jpeg"
                  @change="onChangeArticleImage($event)"
                />
              </div>
            </div>
            <div class="row mb-3 row-input align-items-center">
              <div class="col-sm-3 text-lg-left px-0 my-auto">
                <label class="cp-label"> 背景画像繰り返し </label>
              </div>
              <div class="custome-input-one-column" style="margin-top: -10px">
                <label
                  class="custom-ListLabel"
                  style="cursor: pointer"
                  :for="`action-${index}`"
                  v-bind:class="{
                    selected: index === infoNew.repeatArticle,
                  }"
                  v-for="(item, index) in listRepeat"
                  :key="index"
                  @click="setArticleRepeat(item.value)"
                >
                  {{ item.text }}
                </label>
              </div>
            </div>
            <div class="row mb-3 row-input align-items-center">
              <div class="col-sm-3 text-lg-left px-0 my-auto">
                <label class="cp-label"> 背景画像固定 </label>
              </div>
              <div class="custome-input-one-column">
                <label
                  class="custom-ListLabel"
                  style="cursor: pointer"
                  :for="`action-${index}`"
                  v-bind:class="{
                    selected: index === infoNew.fixedArticle,
                  }"
                  v-for="(item, index) in listFixed"
                  :key="index"
                  @click="setArticleFixed(item.value)"
                >
                  {{ item.text }}
                </label>
              </div>
            </div>
            <div class="row mb-3 row-input align-items-center">
              <div class="col-sm-3 text-lg-left px-0 my-auto">
                <label class="cp-label"> 背景画像の配置 </label>
              </div>
              <div class="custome-input-one-column">
                <label
                  class="custom-ListLabel"
                  style="cursor: pointer"
                  :for="`action-${index}`"
                  v-bind:class="{
                    selected: index === infoNew.placeArticle,
                  }"
                  v-for="(item, index) in listPlace"
                  :key="index"
                  @click="setArticlePlace(item.value)"
                >
                  {{ item.text }}
                </label>
              </div>
            </div>
            <div class="row mb-3 row-input align-items-center">
              <div class="col-sm-3 text-lg-left px-0 my-auto">
                <label class="cp-label"> 影の設定 </label>
              </div>
              <div class="custome-input-one-column">
                <label
                  class="custom-ListLabel"
                  style="cursor: pointer"
                  :for="`action-${index}`"
                  v-bind:class="{
                    selected: index === infoNew.shadow,
                  }"
                  v-for="(item, index) in listShadow"
                  :key="index"
                  @click="setArticleShadow(item.value)"
                >
                  {{ item.text }}
                </label>
              </div>
            </div>
            <div class="row mb-3 row-input">
              <div class="col-sm-3 text-lg-left px-0 my-auto">
                <label class="cp-label" for="post_banner-image-button">
                  影の色
                </label>
              </div>
              <div class="custome-input-one-column">
                <v-input-colorpicker
                  v-model="infoNew.colorShadow"
                  class="input-color2 color-field position-absolute"
                  style="z-index: -1"
                />
                <input
                  class="input color-field position-relative"
                  style="width: 45%; margin-left: 0px"
                  type="text"
                  v-model="infoNew.colorShadow"
                  @click="changeColor('input-color2')"
                  v-bind:style="{ background: this.infoNew.colorShadow }"
                />
                <span>色を選択</span>
              </div>
            </div>
          </div>
          <!-- TYPE INPUT FREE -->
          <div class="setting-type-5" v-if="user_type == typeCheck['cdea']">
            <div class="row row-input">
              <label class="col-sm-12 font-weight-bold cp-label">追加CSS</label>
              <CTextarea
                v-model="infoNew.css"
                class="w-100 cp-4"
                rows="8"
              ></CTextarea>
            </div>
            <div class="row row-input">
              <label class="col-sm-12 font-weight-bold cp-label"
                >追加Javascript</label
              >
              <CTextarea
                v-model="infoNew.js"
                class="w-100 cp-4"
                rows="8"
              ></CTextarea>
            </div>
          </div>
        </div>
      </form>
      <div class="row justify-content-center">
        <b-button
          v-on:click.prevent="save()"
          :disabled="loadingSave"
          variant="success"
          class="mr-4 btn-color"
        >
          <b-spinner v-if="loadingSave" small></b-spinner>
          編集内容を反映
        </b-button>
        <b-button
          variant="secondary"
          class="mr-4 back-btn"
          v-on:click="cancel()"
          >戻る</b-button
        >
      </div>
    </div>
    <b-modal
      id="setting-condition"
      modal-class="setting-condition"
      ref="setting-condition"
      size="lg"
      title="ユーザーが所持する情報を設定"
      hide-footer
    >
      <div
        class="ml-5 mb-4"
        v-for="(val, index) in conditions"
        v-bind:key="index"
      >
        <div class="d-flex align-items-center mb-2">
          <div>
            <b-form-radio-group
              v-model="val.is_content_slag"
              :options="condition_types"
              :name="'plain' + index"
              @change="changeTypeCondition(val, index)"
              plain
              stacked
            ></b-form-radio-group>
          </div>
          <div class="ml-4 mr-4" style="width: 60%">
            <div
              class="custom-select-2 w-100 custome-multi-slag m-0"
              v-if="val.is_content_slag == 1"
            >
              <multiselect
                v-model="val.id_check"
                tag-placeholder="コンテンツ追加"
                placeholder="コンテンツ追加"
                label="text"
                track-by="value"
                :options="listContentOptions"
                :multiple="true"
                :taggable="true"
                :close-on-select="false"
                select-label="入力して選択する"
                deselect-label="入力して削除する"
                selected-label="選択された"
              >
                <template v-slot:no-options> リストは空です </template>
              </multiselect>
            </div>
            <div class="custom-select-2 w-100 custome-multi-slag m-0" v-else>
              <multiselect
                v-model="val.id_check"
                tag-placeholder="スラグ追加"
                placeholder="スラグ追加"
                label="slag_name"
                track-by="slag_id"
                :options="listSlagOptions"
                :multiple="true"
                :taggable="true"
                :close-on-select="false"
                :select-label="'入力して選択する'"
                :deselect-label="'入力して削除する'"
                :selected-label="'選択された'"
              >
                <template v-slot:no-options> リストは空です </template>
              </multiselect>
            </div>
          </div>
          <div>
            <b-form-radio-group
              v-model="val.is_owned"
              :options="typeOwn"
              :name="'plain1' + index"
              plain
              stacked
            ></b-form-radio-group>
          </div>
          <b-button
            v-if="conditions.length > 1"
            variant="dark"
            class="rounded-circle custome-btn-sub float-right mt-0 ml-4 bt-sub"
            style="border-radius: 50% !important"
            v-on:click="subCondition(index)"
            ><b-icon icon="dash" scale="2"
          /></b-button>
        </div>
        <div>
          <b-button class="mr-4 add-btn" v-on:click="addCondition(index)">
            and設定追加
          </b-button>
        </div>
      </div>

      <div class="d-flex mt-5 justify-content-center">
        <b-button
          @click="saveCondition()"
          class="mr-4 btn-color sale-btn text-white"
        >
          <span>設定</span>
        </b-button>
        <b-button class="mr-4 back-btn" @click="closeSaveCondition()">
          閉じる
        </b-button>
      </div>
    </b-modal>
    <b-modal id="delete-content" hide-header hide-footer>
      <div class="d-block text-center">
        <h5>
          指定したエディタを削除します。よろしければ「削除」を選択してください。
        </h5>
      </div>
      <div class="d-flex justify-content-center">
        <b-button
          class="mx-2 mt-3 btn btn-danger"
          block
          @click="deleteContent()"
          >削除</b-button
        >
        <b-button
          class="mx-2 mt-3 btn btn-secondary"
          block
          @click="cancelDelete()"
        >
          キャンセル
        </b-button>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import "pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css";
import $ from "jquery";
import "@fortawesome/fontawesome-free/css/fontawesome.css";
import "@fortawesome/fontawesome-free/css/regular.css";
import "@fortawesome/fontawesome-free/css/solid.css";
import { Api } from "../../utils/http-common.js";
import { Urls } from "../../utils/urls";
import { Constants } from "../../utils/constants.js";
import InputColorPicker from "vue-native-color-picker";
import Editor from "../../components/editor/index.vue";
import { eventBus } from "../../main";
import vClickOutside from "v-click-outside";

$.extend(true, $.fn.datetimepicker.defaults, {
  icons: {
    time: "far fa-clock",
    date: "far fa-calendar",
    up: "fas fa-arrow-up",
    down: "fas fa-arrow-down",
    previous: "fas fa-chevron-left",
    next: "fas fa-chevron-right",
    today: "fas fa-calendar-check",
    clear: "far fa-trash-alt",
    close: "far fa-times-circle",
  },
});

export default {
  name: "createFixedPage",
  components: {
    Editor,
    "v-input-colorpicker": InputColorPicker,
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      listSetting: [
        { value: 1, text: "基本設定" },
        { value: 2, text: "詳細設定" },
        { value: 3, text: "期間設定" },
        { value: 4, text: "デザイン" },
      ],
      listCondition: [
        { value: 1, text: "コンテンツ付与" },
        { value: 2, text: "スラグ付与" },
        { value: 3, text: "ポイント付与" },
      ],
      typeSetting: 1,
      condition_type: 1,
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      optionPublic: [
        { text: "非公開", value: 0 },
        { text: "公開中", value: 1 },
      ],
      content_condition_1: [],
      content_condition_2: [],
      content_condition_3: [],
      content_condition_4: [],
      slag_condition_1: [],
      slag_condition_2: [],
      slag_condition_3: [],
      slag_condition_4: [],
      infoNew: {
        no: localStorage.getItem(Constants.NO_FIXED_PAGE),
        title: "",
        type: 5,
        description: [
          {
            description: "",
            content_condition_1: [],
            content_condition_2: [],
            content_condition_3: [],
            content_condition_4: [],
            slag_condition_1: [],
            slag_condition_2: [],
            slag_condition_3: [],
            slag_condition_4: [],
            show: true,
          },
        ],
        start_access_date: "",
        end_access_date: "",
        is_public: 1,
        colorBackground: "#ffffff",
        repeatBackground: 0,
        fixedBackground: 0,
        placeBackground: 0,
        colorArticle: "#ffffff",
        repeatArticle: 0,
        fixedArticle: 0,
        placeArticle: 0,
        shadow: 0,
        colorShadow: "#000000",
        indBackgroundRepeat: 0,
        imgHeader: "",
        imgBackground: "",
        imgArticle: "",
        css: "",
        js: "",
        condition_type: 1,
        condition_json: [
          {
            title: "",
            condition: [],
          },
        ],
      },
      optionsDate: {
        format: "YYYY-MM-DD HH:mm:ss",
        useCurrent: false,
        showClear: false,
        showClose: false,
        locale: "ja",
      },
      isEdit: false,
      loadingSave: false,
      loadingUpload: false,
      color: "",
      listRepeat: [
        { text: "繰り返す", value: 0 },
        { text: "横方向に繰り返す", value: 1 },
        { text: "縦方向に繰り返す", value: 2 },
        { text: "繰り返さない", value: 3 },
      ],
      listFixed: [
        { text: "固定する", value: 0 },
        { text: "固定しない", value: 1 },
      ],
      listPlace: [
        { text: "右よせ", value: 0 },
        { text: "中央", value: 1 },
        { text: "左よせ", value: 2 },
      ],
      listShadow: [
        { text: "ON", value: 0 },
        { text: "OFF", value: 1 },
      ],
      action: 0,
      check: 0,
      addNew: false,
      checkPreview: 1,
      checkAddEditor: 1,
      ind: null,
      option1Column: {
        urlHeaderImg: "",
        flagLogo: false,
        fileNameHeader: "",
        urlBackground: "",
        flagBackground: false,
        fileNameBackground: "",
        urlArticeBackground: "",
        flagArticle: false,
        fileNameArtice: "",
        checkHeader: "",
      },
      user_type: localStorage.getItem(
        Constants.USER_TYPE_ADMIN +
          (this.$route.params.shopId
            ? "_" + Constants.DOMAIN + "_" + this.$route.params.shopId
            : "_" + Constants.DOMAIN)
      ),
      typeCheck: Constants.USER_TYPE_DEFAULT,
      listSlagOptions: [],
      listContentOptions: [],
      disabledConditionContent2: false,
      disabledConditionContent3: false,
      disabledConditionSlag2: false,
      disabledConditionSlag3: false,
      conditions: [
        {
          id_check: [],
          is_content_slag: 0,
          is_owned: 0,
        },
      ],
      condition_types: [
        { text: "コンテンツ", value: 1 },
        { text: "スラグ", value: 0 },
      ],
      typeOwn: [
        { text: "所持", value: 1 },
        { text: "未所持", value: 0 },
      ],
      contentPos: null,
      createFp: true,
      typeEvent: null,
      isDisabled: false,
      positionPaste: 0,
      allHistoryEditor: [[null]],
      allIdUndo: [],
      showEditor: true,
    };
  },
  async created() {
    const request = {
      shop_id: this.shop_id,
    };
    await this.showMyProfileSetting(request);
    await this.getALLlistSlag(request);

    const requestContent = {
      page: 1,
      data: {
        shop_id: this.shop_id,
        limit: "all",
      },
    };
    await this.getListContents(requestContent);

    this.infoNew = localStorage.getItem(Constants.PAGE_PREVIEW)
      ? JSON.parse(localStorage.getItem(Constants.PAGE_PREVIEW))
      : this.infoNew;
    this.option1Column = localStorage.getItem(Constants.PREVIEW_COLUMN_PAGE)
      ? JSON.parse(localStorage.getItem(Constants.PREVIEW_COLUMN_PAGE))
      : this.option1Column;
    if (localStorage.getItem(Constants.PAGE_PREVIEW)) {
      this.checkPreview = 3;
    }
    if (localStorage.getItem(Constants.PREVIEW_COLUMN_PAGE)) {
      this.option1Column.urlHeaderImg = this.checkHeaderImg;
      this.option1Column.urlBackground = this.checkBackgroundImg;
      this.option1Column.urlArticeBackground = this.checkArticleImg;
    }
  },
  mounted() {
    document.addEventListener("mousedown", function (e) {
      if (
        document.getElementById("editor-link") &&
        !document.getElementById("editor-link").contains(e.target)
      ) {
        let parent = document.getElementsByClassName(
          "ce-inline-toolbar--left-oriented"
        )[0];
        parent.style.removeProperty("opacity");
        parent.style.removeProperty("visibility");
      }
    });
  },
  computed: {
    ...mapGetters([
      "listSlag",
      "listContents",
      "detailMyProfileSetting",
      "success",
      "message",
      "error",
      "checkHeaderImg",
      "checkBackgroundImg",
      "checkArticleImg",
      "listFixedPage",
      "isShowLayout",
    ]),
  },
  watch: {
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["success", false]);
        this.$store.commit("set", ["message", ""]);
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["error", false]);
        this.$store.commit("set", ["message", ""]);
      }
    },
    listFixedPage() {
      let id = this.$route.params.id ? this.$route.params.id : "";
      if (!id) {
        this.infoNew.no = this.listFixedPage.length + 1;
      }
    },
    detailMyProfileSetting() {
      this.infoNew.title = this.detailMyProfileSetting.title;
      this.infoNew.condition_type = this.detailMyProfileSetting.condition_type;
      this.infoNew.condition_json = this.detailMyProfileSetting.condition_json;
      this.infoNew.description = JSON.parse(
        this.detailMyProfileSetting.description
      );
      if (this.infoNew.description.length > 0) {
        this.infoNew.description[0].show = false;
        this.isEdit = true;
        const selft = this;
        setTimeout(function () {
          selft.allHistoryEditor = [];
          selft.infoNew.description.forEach((editor) => {
            let indexs = [];
            editor.show = true;
            editor.description.blocks.forEach((el, index) => {
              if (el.type == "paragraph") {
                let text = el.data.text;
                let div = document.createElement("div");
                div.innerHTML = text;
                let children = selft.getChildrent(div);
                if (children.innerHTML.includes("<br>")) {
                  let innerHTML = children.innerHTML.split("<br>");
                  if (!innerHTML[0].replace(/&nbsp;/g, "")) {
                    innerHTML.splice(0, 1);
                    children.innerHTML = innerHTML.join("<br>");
                    el.data.text = div.innerHTML;
                    indexs.push(index);
                  }
                }
              }
            });
            indexs.forEach((i) => {
              editor.description.blocks.splice(i, 0, {
                id: (Math.random() + 1).toString(36).substring(0, 10),
                type: "paragraph",
                data: {
                  text: "",
                  alignment: "left",
                  lineHeight: null,
                },
                tunes: {
                  anyTuneName: {
                    alignment: "left",
                  },
                },
              });
            });
            selft.allHistoryEditor.push([editor.description]);
            selft.allIdUndo.push(0);
          });
        }, 100);
      }
      this.infoNew.start_access_date = this.detailMyProfileSetting
        .start_access_date
        ? this.detailMyProfileSetting.start_access_date
        : "";
      this.infoNew.end_access_date = this.detailMyProfileSetting.end_access_date
        ? this.detailMyProfileSetting.end_access_date
        : "";
      this.infoNew.is_public = this.detailMyProfileSetting.is_public;
      this.infoNew.colorBackground =
        this.detailMyProfileSetting.background_color;
      this.infoNew.repeatBackground =
        this.detailMyProfileSetting.dulipcate_background;
      this.infoNew.fixedBackground =
        this.detailMyProfileSetting.fixed_background;
      this.infoNew.placeBackground =
        this.detailMyProfileSetting.position_background;
      this.infoNew.colorArticle =
        this.detailMyProfileSetting.article_background_color;
      this.infoNew.repeatArticle =
        this.detailMyProfileSetting.article_background_repeat;
      this.infoNew.fixedArticle =
        this.detailMyProfileSetting.article_background_fixed;
      this.infoNew.placeArticle =
        this.detailMyProfileSetting.article_background_position;
      this.infoNew.shadow =
        this.detailMyProfileSetting.article_background_shadow;
      this.infoNew.colorShadow = this.detailMyProfileSetting.shadow_color;
      this.option1Column.urlHeaderImg = localStorage.getItem(
        Constants.PREVIEW_COLUMN_PAGE
      )
        ? this.checkHeaderImg
        : this.detailMyProfileSetting.logo_image
        ? Constants.URL_BE + "/" + this.detailMyProfileSetting.logo_image
        : "";
      this.option1Column.urlBackground = localStorage.getItem(
        Constants.PREVIEW_COLUMN_PAGE
      )
        ? this.checkBackgroundImg
        : this.detailMyProfileSetting.background_image
        ? Constants.URL_BE + "/" + this.detailMyProfileSetting.background_image
        : "";
      this.option1Column.urlArticeBackground = localStorage.getItem(
        Constants.PREVIEW_COLUMN_PAGE
      )
        ? this.checkArticleImg
        : this.detailMyProfileSetting.article_background_image
        ? Constants.URL_BE +
          "/" +
          this.detailMyProfileSetting.article_background_image
        : "";
      this.$store.commit("set", [
        "checkHeaderImg",
        this.option1Column.urlHeaderImg,
      ]);
      this.$store.commit("set", [
        "checkBackgroundImg",
        this.option1Column.urlBackground,
      ]);
      this.$store.commit("set", [
        "checkArticleImg",
        this.option1Column.urlArticeBackground,
      ]);
      this.infoNew.css = this.detailMyProfileSetting.css
        ? this.detailMyProfileSetting.css
        : "";
      this.infoNew.js = this.detailMyProfileSetting.js
        ? this.detailMyProfileSetting.js
        : "";
      this.infoNew = localStorage.getItem(Constants.PAGE_PREVIEW)
        ? JSON.parse(localStorage.getItem(Constants.PAGE_PREVIEW))
        : this.infoNew;

      // // Point
      // this.infoNew.pointGive = this.detailMyProfileSetting.point_give
      //   ? this.detailMyProfileSetting.point_give
      //   : 0;

      // if (
      //   this.detailMyProfileSetting.point_give_condition &&
      //   this.detailMyProfileSetting.point_give_condition.length > 0
      // ) {
      //   this.detailMyProfileSetting.point_give_condition.forEach(
      //     (item, index) => {
      //       if (item.length > 0) {
      //         this.detailMyProfileSetting.point_give_condition[index]["point"] =
      //           item["point"] ? item["point"] : 0;

      //         let arrCondition = [];
      //         if (item["condition"]) {
      //           item["condition"].forEach((itemC, indexC) => {
      //             if (itemC["is_content_slag"] == 1) {
      //               arrCondition = this.convertContent(itemC["id_check"]);
      //             } else {
      //               arrCondition = this.convertSlag(itemC["id_check"]);
      //             }

      //             this.detailMyProfileSetting.point_give_condition[index][
      //               "condition"
      //             ][indexC]["id_check"] = arrCondition;
      //           });
      //         }
      //       }
      //     }
      //   );

      //   this.infoNew.condition_json = Object.values(
      //     this.detailMyProfileSetting.point_give_condition
      //   );
      // }

      this.changeStatusEdit();
    },
    listSlag() {
      this.listSlagOptions = this.listSlag.map((item) => ({
        slag_id: item.id,
        slag_name: item.slag_name ? item.slag_name : item.slag_name_en,
      }));
    },
    listContents() {
      this.listContentOptions = this.listContents.map((item) => ({
        value: item.id,
        text: item.title,
      }));
    },
  },
  methods: {
    ...mapActions({
      getALLlistSlag: "getALLlistSlag",
      getListContents: "getListContents",
      showMyProfileSetting: "showMyProfileSetting",
    }),
    getChildrent(div) {
      if (div.innerHTML[0] == "<" && div.innerHTML.substr(0, 4) != "<br>") {
        let children = div.children[0];
        if (children) {
          return this.getChildrent(children);
        } else {
          return div;
        }
      } else {
        return div;
      }
    },
    confirmDelContent(contentPos) {
      this.positionPaste = window.scrollY;
      this.contentPos = contentPos;
      this.$bvModal.show("delete-content");
    },
    cancelDelete() {
      this.contentPos = null;
      this.$bvModal.hide("delete-content");
    },
    deleteContent() {
      this.check = 0;
      this.typeEvent = "delete";
      this.isDisabled = true;
      eventBus.$emit("getFormEditor");
    },
    upContent(index) {
      this.positionPaste = window.scrollY;
      this.contentPos = index;
      this.typeEvent = "up";
      this.isDisabled = true;
      eventBus.$emit("getFormEditor");
    },
    downContent(index) {
      this.positionPaste = window.scrollY;
      this.contentPos = index;
      this.typeEvent = "down";
      this.isDisabled = true;
      eventBus.$emit("getFormEditor");
    },
    copyPage(index) {
      this.contentPos = index;
      this.typeEvent = "copy";
      this.isDisabled = true;
      eventBus.$emit("getFormEditor");
    },
    pastePage(index) {
      this.positionPaste = window.scrollY;
      this.contentPos = index;
      this.typeEvent = "paste";
      this.isDisabled = true;
      eventBus.$emit("getFormEditor");
    },
    cancel() {
      localStorage.removeItem(Constants.PAGE_PREVIEW);
      localStorage.removeItem(Constants.NO_FIXED_PAGE);
      localStorage.removeItem(Constants.PREVIEW_COLUMN_PAGE);
      this.$store.commit("set", ["checkHeaderImg", ""]);
      this.$store.commit("set", ["checkBackgroundImg", ""]);
      this.$store.commit("set", ["checkArticleImg", ""]);
      this.$router.push({
        name: this.$route.params.shopId
          ? "listFixedPage"
          : "listFixedPage domain",
      });
    },
    async handleImageAdded(file, Editor, cursorLocation, resetUploader) {
      if (file.size / 1024 / 1024 > 100) {
        this.$toasted.error("画像1枚につき100MBまで");
        resetUploader();
      } else if (
        file.type != "image/jpg" &&
        file.type != "image/png" &&
        file.type != "image/jpeg"
      ) {
        this.$toasted.error("画像はjpeg、jpg、png形式である必要があります。");
        resetUploader();
      } else {
        var formData = new FormData();
        formData.append("image", file);
        formData.append("id", this.$route.params.id);

        const url = `${Urls.PAGE}/${Urls.UPLOAD}`;
        const method = "POST";
        await Api.adminRequestServer({
          method,
          url,
          data: formData,
        })
          .then((response) => {
            const url = response.data.data; // Get url from response
            Editor.insertEmbed(cursorLocation, "image", url);
            resetUploader();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    handleImageRemove(file) {
      const url = `${Urls.PAGE}/${Urls.UPLOAD}`;
      const method = "POST";
      Api.adminRequestServer({
        method,
        url,
        data: file,
      });
    },
    toggleModal() {
      document.getElementById("idSelectFile").click();
    },
    async changePDF(e, index) {
      var formData = new FormData();
      formData.append("image", e.target.files[0]);
      if (e.target.files[0].size / 1024 / 1024 > 10) {
        this.$toasted.error(
          "アップロード可能なファイルのサイズは10MBまでです。"
        );
      } else {
        this.loadingUpload = true;
        const url = `${Urls.PAGE}/${Urls.UPLOAD}`;
        const method = "POST";
        await Api.adminRequestServer({
          method,
          url,
          data: formData,
        })
          .then((response) => {
            const url = response.data.data; // Get url from response
            this.infoNew.description[index].description +=
              '<iframe class="ql-pdf" frameborder="0" allowfullscreen="true" src="' +
              url +
              '"></iframe><p><br></p>';
            this.loadingUpload = false;
            eventBus.$emit("getFormEditor");
          })
          .catch(() => {
            this.loadingUpload = false;
          });
      }
    },
    async save() {
      this.loadingSave = true;
      if (
        this.infoNew.start_access_date &&
        this.infoNew.end_access_date &&
        this.infoNew.start_access_date > this.infoNew.end_access_date
      ) {
        this.$toasted.error("終了日には、開始日より後の日付を指定してください");
        this.loadingSave = false;
      } else {
        this.checkAddEditor = 1;
        this.check = 0;
        this.changeStatusEdit();
        eventBus.$emit("getFormEditor");
      }
    },
    preview() {
      localStorage.removeItem(Constants.PAGE_PREVIEW);
      localStorage.removeItem(Constants.PREVIEW_COLUMN_PAGE);
      this.checkPreview = 2;
      this.checkAddEditor = 1;
      this.check = 0;
      eventBus.$emit("getFormEditor");
    },
    addNewDescription(index) {
      this.positionPaste = window.scrollY;
      this.typeEvent = "add";
      this.contentPos = index;
      this.isDisabled = true;
      this.check = 0;
      this.checkAddEditor = 2;
      this.ind = index;
      this.changeStatusEdit();
      eventBus.$emit("getFormEditor");
    },
    changeColor(name) {
      document.getElementsByClassName(name)[0].click();
    },
    setAction(value, index) {
      this.action = index;
    },
    changeStatusEdit() {
      this.isEdit = false;
    },
    revertFile(oldFile, fileName) {
      var dataurl = oldFile[0].src;
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], fileName, { type: mime });
    },
    async setValue(e, ind) {
      this.infoNew.description[ind].description = e;
      this.check += 1;
      if (this.check == this.infoNew.description.length) {
        let shop_id = this.shop_id;
        if (this.option1Column.fileNameHeader) {
          var imgPreViewHeader =
            document.getElementsByClassName("preview-img-header");
          this.infoNew.imgHeader = this.revertFile(
            imgPreViewHeader,
            this.option1Column.fileNameHeader
          );
        }
        if (this.option1Column.fileNameBackground) {
          var imgPreViewBackground = document.getElementsByClassName(
            "preview-img-background"
          );
          this.infoNew.imgBackground = this.revertFile(
            imgPreViewBackground,
            this.option1Column.fileNameBackground
          );
        }
        if (this.option1Column.fileNameArtice) {
          var imgPreViewArtice =
            document.getElementsByClassName("preview-img-artice");
          this.infoNew.imgArticle = this.revertFile(
            imgPreViewArtice,
            this.option1Column.fileNameArtice
          );
        }
        if (this.infoNew.description && this.infoNew.description.length > 0) {
          this.infoNew.description.map((item) => {
            const blocksData = item.description.blocks;
            blocksData.map((itemBlock) => {
              if (itemBlock.type === "tableOfContents") {
                itemBlock.data.items = itemBlock.data.items.map(
                  (itemTOC) =>
                    (itemTOC = {
                      text: itemTOC,
                      href: blocksData[blocksData.indexOf(itemBlock)].id,
                    })
                );
              }
            });
          });
        }

        const request = {
          shop_id: shop_id,
          title: this.infoNew.title,
          description: this.infoNew.description,
          start_access_date: this.infoNew.start_access_date
            ? this.infoNew.start_access_date
            : "",
          end_access_date: this.infoNew.end_access_date
            ? this.infoNew.end_access_date
            : "",
          condition_type: this.infoNew.condition_type,
          condition_json: this.infoNew.condition_json,
          is_public: this.infoNew.is_public,
          colorBackground: this.infoNew.colorBackground,
          repeatBackground: this.infoNew.repeatBackground,
          fixedBackground: this.infoNew.fixedBackground,
          placeBackground: this.infoNew.placeBackground,
          colorArticle: this.infoNew.colorArticle,
          repeatArticle: this.infoNew.repeatArticle,
          fixedArticle: this.infoNew.fixedArticle,
          placeArticle: this.infoNew.placeArticle,
          shadow: this.infoNew.shadow,
          colorShadow: this.infoNew.colorShadow,
          css: this.infoNew.css,
          js: this.infoNew.js,
        };
        const reqOptionColumnPage = {
          urlHeaderImg: "",
          flagLogo: this.option1Column.flagLogo,
          fileNameHeader: this.option1Column.fileNameHeader,
          urlBackground: "",
          flagBackground: this.option1Column.flagBackground,
          fileNameBackground: this.option1Column.fileNameBackground,
          urlArticeBackground: "",
          flagArticle: this.option1Column.flagArticle,
          fileNameArtice: this.option1Column.fileNameArtice,
        };
        if (this.checkPreview == 2) {
          localStorage.setItem(Constants.PAGE_PREVIEW, JSON.stringify(request));
          localStorage.setItem(
            Constants.PREVIEW_COLUMN_PAGE,
            JSON.stringify(reqOptionColumnPage)
          );
          this.$store.commit("set", ["isShowLayout", true]);
          const routeData = this.$router.resolve({
            name: this.$route.params.shopId
              ? "previewColumnPage"
              : "previewColumnPage domain",
          });
          window.open(routeData.href, "_blank");
          this.checkPreview = 3;
        } else if (this.checkAddEditor == 2) {
          let obj = { description: "", show: true };
          if (this.ind != null) {
            this.infoNew.description.splice(this.ind + 1, 0, obj);
            this.allHistoryEditor.splice(this.ind + 1, 0, [null]);
            this.allIdUndo.splice(this.ind + 1, 0, 0);
            this.isEdit = !this.isEdit;
            var block = document.getElementById("editor-" + (this.ind + 1));
            if (block) {
              block
                .getElementsByClassName("codex-editor")[0]
                .classList.add("codex-editor--empty");
              var child = block.getElementsByClassName("codex-editor")[0];
              if (child) {
                var content = child.getElementsByClassName("ce-block__content");
                if (content.length > 0) {
                  for (var i = 0; i < content.length; i++) {
                    var descript =
                      content[i].getElementsByClassName("ce-paragraph");
                    var descriptFile =
                      content[i].getElementsByClassName("cdx-block");
                    if (descript && descript.length > 0)
                      descript[0].innerHTML = "";
                    if (descriptFile && descriptFile.length > 0)
                      descriptFile[0].innerHTML = "";
                  }
                }
              }
            }
            const a = JSON.parse(JSON.stringify(this.infoNew.description));
            this.infoNew.description = [];
            this.isEdit = false;
            const selft = this;
            setTimeout(function () {
              selft.infoNew.description = a;
              selft.isDisabled = false;
            }, 200);
            this.ind = null;
          }
        } else if (this.contentPos == null) {
          var formData = new FormData();
          formData.append("shop_id", request.shop_id);
          formData.append("title", request.title);
          formData.append(
            "description",
            JSON.stringify(this.infoNew.description)
          );
          formData.append("start_access_date", request.start_access_date);
          formData.append("end_access_date", request.end_access_date);
          formData.append("condition_type", request.condition_type);
          formData.append(
            "condition_json",
            JSON.stringify(this.infoNew.condition_json)
          );
          formData.append("is_public", request.is_public);
          formData.append(
            "logo_image",
            this.infoNew.imgHeader ? this.infoNew.imgHeader : ""
          );
          formData.append("flagLogo", reqOptionColumnPage.flagLogo);
          formData.append("background_color", request.colorBackground);
          formData.append(
            "background_image",
            this.infoNew.imgBackground ? this.infoNew.imgBackground : ""
          );
          formData.append("flagBackground", reqOptionColumnPage.flagBackground);
          formData.append("dulipcate_background", request.repeatBackground);
          formData.append("fixed_background", request.fixedBackground);
          formData.append("position_background", request.placeBackground);
          formData.append("article_background_color", request.colorArticle);
          formData.append(
            "article_background_image",
            this.infoNew.imgArticle ? this.infoNew.imgArticle : ""
          );
          formData.append("flagArticle", reqOptionColumnPage.flagArticle);
          formData.append("article_background_repeat", request.repeatArticle);
          formData.append("article_background_fixed", request.fixedArticle);
          formData.append("article_background_position", request.placeArticle);
          formData.append("article_background_shadow", request.shadow);
          formData.append("shadow_color", request.colorShadow);
          formData.append("css", request.css);
          formData.append("js", request.js);

          const dataReturn = await this.$store.dispatch(
            "updateMyProfileSetting",
            formData
          );
          if (dataReturn.success) {
            localStorage.removeItem(Constants.PAGE_PREVIEW);
            localStorage.removeItem(Constants.PREVIEW_COLUMN_PAGE);
          } else {
            this.check = 0;
          }
          this.loadingSave = false;
        }
      }
      if (
        ind == this.infoNew.description.length - 1 &&
        this.contentPos != null
      ) {
        if (this.typeEvent == "delete") {
          this.infoNew.description.splice(this.contentPos, 1);
          this.allHistoryEditor.splice(this.contentPos, 1);
          this.allIdUndo.splice(this.contentPos, 1);
        }
        if (this.typeEvent == "up") {
          let newIndex =
            this.contentPos > 0
              ? this.contentPos - 1
              : this.infoNew.description.length - 1;

          this.infoNew.description = this.array_move(
            this.infoNew.description,
            this.contentPos,
            newIndex
          );
          this.allHistoryEditor = this.array_move(
            this.allHistoryEditor,
            this.contentPos,
            newIndex
          );
          this.allIdUndo = this.array_move(
            this.allIdUndo,
            this.contentPos,
            newIndex
          );
        }
        if (this.typeEvent == "down") {
          let newIndex =
            this.contentPos == this.infoNew.description.length - 1
              ? 0
              : this.contentPos + 1;

          this.infoNew.description = this.array_move(
            this.infoNew.description,
            this.contentPos,
            newIndex
          );
          this.allHistoryEditor = this.array_move(
            this.allHistoryEditor,
            this.contentPos,
            newIndex
          );
          this.allIdUndo = this.array_move(
            this.allIdUndo,
            this.contentPos,
            newIndex
          );
        }
        if (
          this.typeEvent == "copy" &&
          this.infoNew.description[this.contentPos].description.blocks
        ) {
          this.typeEvent = null;
          const selft = this;
          navigator.clipboard
            .writeText(
              JSON.stringify(
                this.infoNew.description[this.contentPos].description
              )
            )
            .then(
              function () {
                selft.$toasted.success("内容がコピーされました。");
                selft.isDisabled = false;
              },
              function () {
                selft.$toasted.error("エラーでコピーできませんでした。");
                selft.isDisabled = false;
              }
            );
          return;
        }
        if (this.typeEvent == "paste") {
          let data = await navigator.clipboard.readText();
          this.isDisabled = false;
          if (
            data &&
            data.includes("blocks") &&
            data.includes("time") &&
            data.includes("version")
          ) {
            let dataObj = JSON.parse(data);
            if (dataObj.blocks.length == 0) return;
            let dataEditor =
              this.infoNew.description[this.contentPos].description;
            if (dataEditor && dataEditor.blocks.length > 0) {
              if (
                dataEditor.blocks.length == 1 &&
                dataEditor.blocks[0].data.text == ""
              ) {
                dataEditor.blocks.splice(0, 1);
              }
              dataObj.blocks.forEach((ele) => {
                dataEditor.blocks.push(ele);
              });
            } else {
              dataEditor = dataObj;
            }
            this.infoNew.description[this.contentPos].description = dataEditor;
            dataEditor["id"] = "editor-" + this.contentPos;
            this.editorChange(dataEditor);
          } else if (data) {
            let dataEditor =
              this.infoNew.description[this.contentPos].description;
            data = data.replace(/\n/g, "<br>");
            if (
              dataEditor.blocks.length == 1 &&
              dataEditor.blocks[0].data.text == ""
            ) {
              dataEditor.blocks.splice(0, 1);
            }
            dataEditor.blocks.push({
              id: (Math.random() + 1).toString(36).substring(0, 10),
              type: "paragraph",
              data: {
                text: data,
                alignment: "left",
                lineHeight: null,
              },
              tunes: {
                anyTuneName: {
                  alignment: "left",
                },
              },
            });
            this.infoNew.description[this.contentPos].description = dataEditor;
            dataEditor["id"] = "editor-" + this.contentPos;
            this.editorChange(dataEditor);
          }
        }
        const a = JSON.parse(JSON.stringify(this.infoNew.description));
        this.infoNew.description = [];
        const selft = this;
        setTimeout(function () {
          selft.infoNew.description = a;
          selft.isDisabled = false;
        }, 100);
        this.$bvModal.hide("delete-content");
      }
    },
    onReady(id) {
      if (
        this.contentPos != null &&
        id.replace("editor-", "") == this.infoNew.description.length - 1 &&
        (this.typeEvent == "paste" ||
          this.typeEvent == "delete" ||
          this.typeEvent == "add")
      ) {
        window.scrollTo(0, this.positionPaste);
        this.contentPos = null;
        this.typeEvent = null;
      }
      if (
        this.contentPos != null &&
        id.replace("editor-", "") == this.infoNew.description.length - 1 &&
        (this.typeEvent == "up" || this.typeEvent == "down")
      ) {
        if (this.typeEvent == "up") {
          this.contentPos =
            this.contentPos == 0
              ? this.infoNew.description.length - 1
              : this.contentPos - 1;
        }
        if (this.typeEvent == "down") {
          this.contentPos =
            this.contentPos == this.infoNew.description.length - 1
              ? 0
              : this.contentPos + 1;
        }
        document.getElementById("editorId" + this.contentPos).scrollIntoView();
        this.contentPos = null;
        this.typeEvent = null;
      }
    },
    array_move(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr; // for testing
    },
    async onChangeHeaderImage(e) {
      if (e.target.files) {
        if (!/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/.test(e.target.value)) {
          this.$toasted.error(
            "「jpeg」「jpg」「png」形式の画像をご利用ください。"
          );
          this.$store.commit("set", ["message", ""]);
          this.$store.commit("set", ["error", false]);
          return false;
        } else if (
          e.target.files[0] &&
          e.target.files[0].size / 1024 / 1024 > 100
        ) {
          this.$toasted.error("画像1枚につき100MBまで");
          this.$store.commit("set", ["message", ""]);
          this.$store.commit("set", ["error", false]);
          return false;
        } else {
          this.option1Column.fileNameHeader = e.target.files[0].name;
          this.option1Column.urlHeaderImg = await this.getBase64(
            e.target.files[0]
          );
          this.$store.commit("set", [
            "checkHeaderImg",
            this.option1Column.urlHeaderImg,
          ]);
          this.option1Column.flagLogo = false;
        }
      }
    },
    deleteImg(type) {
      if (type == 1) {
        this.$refs.headerFile.value = null;
        this.option1Column.fileNameHeader = "";
        this.option1Column.urlHeaderImg = "";
        this.$store.commit("set", [
          "checkHeaderImg",
          this.option1Column.urlHeaderImg,
        ]);
        this.option1Column.flagLogo = true;
      } else if (type == 2) {
        this.$refs.backgroundFile.value = null;
        this.option1Column.fileNameBackground = "";
        this.option1Column.urlBackground = "";
        this.$store.commit("set", [
          "checkBackgroundImg",
          this.option1Column.urlBackground,
        ]);
        this.option1Column.flagBackground = true;
      } else {
        this.$refs.articleFile.value = null;
        this.option1Column.fileNameArtice = "";
        this.option1Column.urlArticeBackground = "";
        this.$store.commit("set", [
          "checkArticleImg",
          this.option1Column.urlArticeBackground,
        ]);
        this.option1Column.flagArticle = true;
      }
    },
    async onChangeBackgroundImage(e) {
      if (e.target.files) {
        if (!/\.(jpg|jpeg|png|gif|JPG|PNG|GIF)$/.test(e.target.value)) {
          this.$toasted.error("图片类型必须是.jpeg,jpg,png,gif中的一种");
          this.$store.commit("set", ["message", ""]);
          this.$store.commit("set", ["error", false]);
          return false;
        } else if (
          e.target.files[0] &&
          e.target.files[0].size / 1024 / 1024 > 100
        ) {
          this.$toasted.error("画像1枚につき100MBまで");
          this.$store.commit("set", ["message", ""]);
          this.$store.commit("set", ["error", false]);
          return false;
        } else {
          this.option1Column.fileNameBackground = e.target.files[0].name;
          this.option1Column.urlBackground = await this.getBase64(
            e.target.files[0]
          );
          this.$store.commit("set", [
            "checkBackgroundImg",
            this.option1Column.urlBackground,
          ]);
          this.option1Column.flagBackground = false;
        }
      }
    },
    async onChangeArticleImage(e) {
      if (e.target.files) {
        if (!/\.(jpg|jpeg|png|JPG|JPEG|PNG)$/.test(e.target.value)) {
          this.$toasted.error(
            "「jpeg」「jpg」「png」形式の画像をご利用ください。"
          );
          this.$store.commit("set", ["message", ""]);
          this.$store.commit("set", ["error", false]);
          return false;
        } else if (
          e.target.files[0] &&
          e.target.files[0].size / 1024 / 1024 > 100
        ) {
          this.$toasted.error("画像1枚につき100MBまで");
          this.$store.commit("set", ["message", ""]);
          this.$store.commit("set", ["error", false]);
          return false;
        } else {
          this.option1Column.fileNameArtice = e.target.files[0].name;
          this.option1Column.urlArticeBackground = await this.getBase64(
            e.target.files[0]
          );
          this.$store.commit("set", [
            "checkArticleImg",
            this.option1Column.urlArticeBackground,
          ]);
          this.option1Column.flagArticle = false;
        }
      }
    },
    getBase64(file) {
      // Returns a promise which gets resolved or rejected based on the reader events
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        // Sets up even listeners BEFORE you call reader.readAsDataURL
        reader.onload = function () {
          const result = reader.result;
          return resolve(result);
        };
        reader.onerror = function (error) {
          return reject(error);
        };
        // Calls reader function
        reader.readAsDataURL(file);
      });
    },
    setBackgroundRepeat(value) {
      // this.infoNew.indBackgroundRepeat = index;
      this.infoNew.repeatBackground = value;
    },
    setBackgroundFixed(value) {
      this.infoNew.fixedBackground = value;
    },
    setBackgroundPlace(value) {
      this.infoNew.placeBackground = value;
    },
    setArticleRepeat(value) {
      this.infoNew.repeatArticle = value;
    },
    setArticleFixed(value) {
      this.infoNew.fixedArticle = value;
    },
    setArticlePlace(value) {
      this.infoNew.placeArticle = value;
    },
    setArticleShadow(value) {
      this.infoNew.shadow = value;
    },
    checkTab(value) {
      this.typeSetting = value;
    },
    showSlagConditionDetail(item, index) {
      this.indexBlock = index;
      const listOptionsContent = this.listContentOptions.reduce(
        (obj, cur) => ({ ...obj, [cur.value]: cur }),
        {}
      );
      const listOptionsSlag = this.listSlagOptions.reduce(
        (obj, cur) => ({ ...obj, [cur.slag_id]: cur }),
        {}
      );
      if (this.infoNew.description[this.indexBlock].content_condition_1) {
        this.content_condition_1 = this.fomatObject(
          this.infoNew.description[this.indexBlock].content_condition_1,
          listOptionsContent
        );
      } else {
        this.content_condition_1 = null;
      }
      if (this.infoNew.description[this.indexBlock].content_condition_2) {
        this.content_condition_2 = this.fomatObject(
          this.infoNew.description[this.indexBlock].content_condition_2,
          listOptionsContent
        );
      } else {
        this.content_condition_2 = null;
      }
      if (this.infoNew.description[this.indexBlock].content_condition_3) {
        this.content_condition_3 = this.fomatObject(
          this.infoNew.description[this.indexBlock].content_condition_3,
          listOptionsContent
        );
      } else {
        this.content_condition_3 = null;
      }
      if (this.infoNew.description[this.indexBlock].content_condition_4) {
        this.content_condition_4 = this.fomatObject(
          this.infoNew.description[this.indexBlock].content_condition_4,
          listOptionsContent
        );
      } else {
        this.content_condition_4 = null;
      }
      if (this.infoNew.description[this.indexBlock].slag_condition_1) {
        this.slag_condition_1 = this.fomatObject(
          this.infoNew.description[this.indexBlock].slag_condition_1,
          listOptionsSlag
        );
      } else {
        this.slag_condition_1 = null;
      }
      if (this.infoNew.description[this.indexBlock].slag_condition_2) {
        this.slag_condition_2 = this.fomatObject(
          this.infoNew.description[this.indexBlock].slag_condition_2,
          listOptionsSlag
        );
      } else {
        this.slag_condition_2 = null;
      }
      if (this.infoNew.description[this.indexBlock].slag_condition_3) {
        this.slag_condition_3 = this.fomatObject(
          this.infoNew.description[this.indexBlock].slag_condition_3,
          listOptionsSlag
        );
      } else {
        this.slag_condition_3 = null;
      }
      if (this.infoNew.description[this.indexBlock].slag_condition_4) {
        this.slag_condition_4 = this.fomatObject(
          this.infoNew.description[this.indexBlock].slag_condition_4,
          listOptionsSlag
        );
      } else {
        this.slag_condition_4 = null;
      }
      this.$bvModal.show("modal-detail-condition");
      this.changeMutiSelectContentCondition();
      this.changeMutiSelectSlagCondition();
    },
    settingCondition(item, index) {
      this.indexBlock = index;
      let conditions = [
        {
          id_check: [],
          is_content_slag: 0,
          is_owned: 0,
        },
      ];

      if (
        !this.infoNew.condition_json[this.indexBlock]["condition"] ||
        this.infoNew.condition_json[this.indexBlock]["condition"].length == 0
      ) {
        this.conditions = conditions;
      } else {
        this.conditions =
          this.infoNew.condition_json[this.indexBlock]["condition"];
      }

      this.$bvModal.show("setting-condition");
    },
    saveCondition() {
      this.infoNew.condition_json[this.indexBlock]["condition"] =
        this.conditions;
      this.$bvModal.hide("setting-condition");
    },
    closeSaveCondition() {
      this.$bvModal.hide("setting-condition");
    },
    converContentCondition(array) {
      if (array && array.length > 0) {
        const listContentsCondition = [];
        array.map((item) => {
          listContentsCondition.push(item.value);
        });
        return listContentsCondition;
      }
    },
    converSlagCondition(array) {
      if (array && array.length > 0) {
        const listContentsCondition = [];
        array.map((item) => {
          listContentsCondition.push(item.slag_id);
        });
        return listContentsCondition;
      }
    },
    fomatObject(array, object) {
      if (array && array.length > 0) {
        let ArrrayObject = [];
        Object.keys(object)
          .filter((key) => array.includes(parseInt(key)))
          .reduce((obj, key) => {
            ArrrayObject.push(object[key]);
          }, {});
        return ArrrayObject;
      }
    },
    changeMutiSelectContentCondition() {
      if (!this.content_condition_1) {
        this.disabledConditionContent2 = true;
        this.content_condition_2 = null;
        this.content_condition_3 = null;
      } else {
        this.disabledConditionContent2 = false;
      }
      if (!this.content_condition_1 || !this.content_condition_2) {
        this.disabledConditionContent3 = true;
        this.content_condition_3 = null;
      } else {
        this.disabledConditionContent3 = false;
      }
    },
    changeMutiSelectSlagCondition() {
      if (!this.slag_condition_1) {
        this.disabledConditionSlag2 = true;
        this.slag_condition_2 = null;
        this.slag_condition_3 = null;
      } else {
        this.disabledConditionSlag2 = false;
      }
      if (!this.slag_condition_1 || !this.slag_condition_2) {
        this.disabledConditionSlag3 = true;
        this.slag_condition_3 = null;
      } else {
        this.disabledConditionSlag3 = false;
      }
    },
    updateCondition() {
      this.infoNew.description[this.indexBlock].content_condition_1 =
        this.converContentCondition(this.content_condition_1);
      this.infoNew.description[this.indexBlock].content_condition_2 =
        this.converContentCondition(this.content_condition_2);
      this.infoNew.description[this.indexBlock].content_condition_3 =
        this.converContentCondition(this.content_condition_3);
      this.infoNew.description[this.indexBlock].content_condition_4 =
        this.converContentCondition(this.content_condition_4);
      this.infoNew.description[this.indexBlock].slag_condition_1 =
        this.converSlagCondition(this.slag_condition_1);
      this.infoNew.description[this.indexBlock].slag_condition_2 =
        this.converSlagCondition(this.slag_condition_2);
      this.infoNew.description[this.indexBlock].slag_condition_3 =
        this.converSlagCondition(this.slag_condition_3);
      this.infoNew.description[this.indexBlock].slag_condition_4 =
        this.converSlagCondition(this.slag_condition_4);
      this.$bvModal.hide("modal-detail-condition");
    },
    closeModalCondition() {
      this.$bvModal.hide("modal-detail-condition");
    },
    addTitle() {
      this.infoNew.condition_json.push({
        title: "",
        condition: [],
      });
    },
    removeListDisplay(index) {
      this.infoNew.condition_json.splice(index, 1);
    },
    addCondition(index) {
      this.conditions.splice(index + 1, 0, {
        id_check: [],
        is_content_slag: 0,
        is_owned: 0,
      });
    },
    subCondition(index) {
      this.conditions.splice(index, 1);
    },
    changeTypeCondition(value) {
      value.id_check = [];
    },
    convertContent(array) {
      array.forEach((subItem, subIndex) => {
        this.listContents.map((value) => {
          if (value.id == subItem.value) {
            array[subIndex] = {
              value: value.id,
              text: value.title,
            };
          }
        });
      });
      return array;
    },
    convertSlag(array) {
      array.forEach((subItem, subIndex) => {
        this.listSlag.map((value) => {
          if (value.id == subItem.slag_id) {
            array[subIndex] = {
              slag_id: value.id,
              slag_name: value.slag_name ? value.slag_name : value.slag_name_en,
            };
          }
        });
      });
      return array;
    },
    editorChange(data) {
      const index = data["id"].split("-")[1];
      if (!this.allHistoryEditor[index]) {
        this.allHistoryEditor[index] = [data];
        this.allIdUndo[index] = 0;
        return;
      }
      let historyEditor = this.allHistoryEditor[index];
      let idUndo = this.allIdUndo[index];
      const dataHis = historyEditor[historyEditor.length - idUndo - 1];
      if (
        dataHis &&
        JSON.stringify(dataHis.blocks) == JSON.stringify(data.blocks)
      )
        return;
      if (idUndo > 0) {
        historyEditor = historyEditor.slice(0, historyEditor.length - idUndo);
        this.allIdUndo[index] = 0;
      }
      historyEditor.push(data);
      if (historyEditor.length <= 20) {
        historyEditor = historyEditor.slice(
          historyEditor.length - 19,
          historyEditor.length
        );
      }
      this.allHistoryEditor[index] = historyEditor;
    },
    undo(index) {
      let historyEditor = this.allHistoryEditor[index];
      let idUndo = this.allIdUndo[index] ? this.allIdUndo[index] : 0;
      if (
        historyEditor.length < 1 ||
        historyEditor == undefined ||
        historyEditor.length <= idUndo + 1
      )
        return;
      idUndo += 1;
      const dataEditor =
        historyEditor.length > 1
          ? historyEditor[historyEditor.length - idUndo - 1]
          : null;
      this.infoNew.description[index].description = dataEditor;
      this.allIdUndo[index] = idUndo;
      this.infoNew.description[index].show = false;
      this.showEditor = false;
      const seflt = this;
      setTimeout(function () {
        seflt.infoNew.description[index].show = true;
        seflt.showEditor = true;
        seflt.contentPos = null;
      }, 100);
    },
    redo(index) {
      this.changeStatusEdit();
      let historyEditor = this.allHistoryEditor[index];
      let idUndo = this.allIdUndo[index];
      if (idUndo == undefined || idUndo <= 0 || historyEditor.length <= 0)
        return;
      idUndo -= 1;
      const dataEditor = historyEditor[historyEditor.length - idUndo - 1];
      this.infoNew.description[index].description = dataEditor;
      this.allIdUndo[index] = idUndo;
      this.infoNew.description[index].show = false;
      this.showEditor = false;
      const seflt = this;
      setTimeout(function () {
        seflt.infoNew.description[index].show = true;
        seflt.showEditor = true;
        seflt.contentPos = null;
      }, 100);
    },
  },
};
</script>
<style lang="scss">
.ql-container {
  border: none !important;
  .ql-editor {
    background-color: white;
    height: auto;
  }
}
.error-input {
  border: solid 1px #e55353 !important;
}
.message-error {
  color: #e55353;
}
.all-description .mt-5:nth-child(1) {
  margin-top: 0 !important;
}
.add-new-description:hover {
  cursor: pointer;
}
.title-btn {
  display: flex;
  margin-top: 20px;
  align-items: center;
  @media (max-width: 767px) {
    display: block;
    button {
      @media (max-width: 767px) {
        display: block;
        margin-top: 10px;
      }
    }
  }
}
.btn-toggle-active {
  display: inline-block;
  position: relative;
  .btn-checkbox-active {
    border: 0;
  }
  input[type="radio"] {
    opacity: 0;
    position: absolute;
    z-index: 1;
    width: 100%;
    inset: 0;
    &:checked ~ .btn-checkbox-active {
      background: #ff644e;
      color: #fff;
    }
  }
  label {
    min-width: 150px;
  }
}
.btn-toggle-active {
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
}
.btn-tab-sale-active {
  background: #ff644e;
  border: none;
  color: #fff;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 0.75rem 1.5rem;
  font-size: 20px;
  line-height: 1.5;
  border-radius: 3px;
}
.btn-tab-sale-inactive {
  color: #636f83;
  border-color: #636f83;
  display: inline-block;
  font-weight: 400;
  color: #3c4b64;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid #636f83;
  padding: 0.75rem 1.5rem;
  font-size: 20px;
  line-height: 1.5;
  border-radius: 3px;
}
.btn-tab-sale-active:after {
  position: absolute;
  bottom: -10px;
  left: 50%;
  margin-left: -10px;
  content: "";
  display: block;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #ff644e;
}
.div-preview-btn {
  position: absolute;
  right: 0;
}
@media (max-width: 1280px) {
  .div-preview-btn {
    margin-left: 40px;
    margin-bottom: 15px;
  }
}
@media (max-width: 1024px) {
  .btn-tab-sale-inactive {
    padding: 0.75rem 1rem;
    font-size: 18px;
    width: 110px;
  }
  .btn-tab-sale-active {
    padding: 0.75rem 1rem;
    font-size: 18px;
    width: 110px;
  }
}
@media (max-width: 450px) {
  .btn-tab-sale-inactive {
    margin-top: 10px;
  }
  .btn-tab-sale-active {
    margin-top: 10px;
  }
  .btn-return-respon {
    margin-top: 5px;
  }
}
.create-content-main {
  .cdx-search-field__input {
    height: unset !important;
    margin: unset !important;
    width: unset !important;
  }
}
.create-content-main .codex-editor .ce-inline-tool-hyperlink--input {
  margin: 0px 0px 10px 0px;
}
.create-content-main .codex-editor .form-control {
  margin: 0px 0px 10px 0px;
}
input::placeholder {
  opacity: 0.5;
  font-weight: normal;
}

.bt-setting {
  background-color: unset;
  border: unset;
}

.bt-setting:active,
.bt-setting:focus,
.bt-setting:hover {
  border: none none !important;
  background-color: unset !important;
  box-shadow: none !important;
}
</style>
